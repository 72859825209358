/* eslint-disable max-lines,todo(eran): will refactor at https://jira.wixpress.com/browse/EE-25400 */
import {
  BILoggerEvents,
  CurrentPrice,
  IFedopsCustomParamsFull,
  IMediaItem,
  InfoSectionEvent,
  IProductDTO,
  IProductPageControllerConfig,
  IProductPageStyleParams,
  IPropsInjectedByViewerScript,
  PagePath,
  ProductPagePagination,
  ProdudctQunatityRange,
  SocialSharingEvent,
  TranslationDictionary,
  UserInput,
  UserInputData,
  UserInputErrors,
} from '../types/app-types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {getTranslations, isWorker} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/utils';
import {
  BiExposureTestName,
  COMPONENTS_ADD_TO_CART_TEXT_KEYS,
  COMPONENTS_OUT_OF_STOCK_TEXT_KEYS,
  ErrorTooltipPlacement,
  ImageModeType,
  Layout,
  LayoutId,
  LayoutNames,
  ModalState,
  MULTILINGUAL_TO_TRANSLATIONS_MAP,
  Origin,
  PRODUCT_PAGE_APP_NAME,
  productPageFedopsEvent,
  ProductType,
  QUICK_VIEW_APP_NAME,
  trackEventMetaData,
  translationPath,
  UserInputType,
} from '../constants';
import {ProductService} from '../services/ProductService';
import {QuantityCalculator} from '@wix/wixstores-client-core/dist/es/src/quantity-calculator/quantityCalculator';
import {
  actualPrice,
  formatCustomTextFields,
  inStock as productInStock,
  userInputsFactory,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import * as _ from 'lodash';
import {
  ActionStatus,
  AddToCartActionOption,
  APP_DEFINITION_ID,
  BiButtonActionType,
  PageMap,
  PubSubEvents,
  STORAGE_PAGINATION_KEY,
  StoresWidgetID,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {all, capitalizeFirstLetters} from '../commons/utils';
import {MultilingualService} from '@wix/wixstores-client-core/dist/src/multilingualService/multilingualService';
import {IAppSettings, IProductOptionSelection} from '@wix/wixstores-graphql-schema/dist/es/src/graphql-schema';
import {IControllerConfig, StructurePage} from '@wix/native-components-infra/dist/src/types/types';
import {parseUrl} from '@wix/native-components-infra/dist/src/urlUtils';
import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core/dist/src/types/site-map';
import {ITrackEventParams} from '@wix/native-components-infra/dist/es/src/types/wix-sdk';
import {clickOnProductDetailsSfParams, exposureEventForTestsParams, socialButtonsParams} from '@wix/bi-logger-ec-sf';
import {ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {updateWixCounters} from '../services/countersApi';
import {
  ProductPageItemData,
  SeoProductBuilder,
} from '@wix/wixstores-client-core/dist/es/src/builders/SeoItemData.builder';
import {SPECS} from '../specs';
import {WishlistActions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/wishlist-actions/WishlistActions';
import {DirectPurchaseService} from '../services/DirectPurchaseService';
import {TrackEventName} from '@wix/wixstores-client-core/dist/es/src/types/track-event';
import {SubscriptionService} from '../services/SubscriptionService';
import {ResultProp, withChangeListener} from '../providers/withChangeListener';
import {GetProductBySlugQuery} from '../graphql/queries-schema';
import {cashierExpressAddressToEcomAddress} from '@wix/wixstores-client-storefront-sdk/dist/src/cart/cashierExpressAddressToEcomAddress/cashierExpressAddressToEcomAddress';
import {CartApi} from '@wix/wixstores-client-storefront-sdk/dist/src/cart/cartApi/CartApi';
import {VolatileCartService} from '../services/VolatileCartService';
import {PaymentBreakdown} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {ShippingContactRestricted, ShippingError} from '@wix/cashier-express-checkout-widget/dist/src/types/Shipping';
import {
  OnPaymentAuthorizedResult,
  PaymentAuthorizedArgs,
} from '@wix/cashier-express-checkout-widget/dist/src/types/ExternalContract';
import {AddToCartService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/add-to-cart-service/AddToCartService';
import {
  ADD_FREE_PRODUCTS_TO_CART_SPEC,
  AddToCartState,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/add-to-cart-service/constants';
import {IFedOpsLogger} from '@wix/native-components-infra/dist/es/src/types/types';
import {ProductPriceService} from '../services/ProductPriceService';
import {
  DynamicPaymentMethodsShape,
  DynamicPaymentMethodsTheme,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/settingsEnums/productPage';
import {Theme} from '@wix/cashier-express-checkout-widget/dist/src/types/Styles';
import {NavigationService} from '../services/NavigationService';
import {CustomUrlApi} from '@wix/wixstores-client-storefront-sdk/dist/es/src/custom-url/customUrlApi/CustomUrlApi';
import {URLUtils} from '@wix/wixstores-client-core/dist/es/src/utils/UrlUtils';

export class ProductPageStore {
  private cashierExpressCheckoutWidgetProps: IPropsInjectedByViewerScript['cashierExpressCheckoutWidgetProps'];
  private countryCodes: GetProductBySlugQuery['localeData']['countries'];
  private directPurchaseService: DirectPurchaseService;
  private isMembersInstalled: boolean;
  private isStartReported: boolean = false;
  private media: IMediaItem[] = [];
  private mergedPublicData: {[p: string]: any};
  private multilingualService: MultilingualService;
  private pagePath;
  private product: IProductDTO;
  private productAddedToWishlist: boolean;
  private readonly addToCartService: AddToCartService;
  private readonly cartApi: CartApi;
  private readonly customUrlApi: CustomUrlApi;
  private readonly currentPath: string[];
  private readonly fedopsLogger: IFedOpsLogger;
  private readonly handleCashierOnClickResult: ResultProp<boolean>;
  private readonly handleCashierPaymentSubmitResult: ResultProp<OnPaymentAuthorizedResult>;
  private readonly navigationContext: IStoreFrontNavigationContext;
  private readonly productService: ProductService;
  private readonly publicData: IControllerConfig['publicData'];
  private sectionUrl: string;
  private subscriptionService: SubscriptionService;
  private translations: TranslationDictionary;
  private translationsPromise: Promise<any>;
  private volatileCartServiceForCashierExpress: VolatileCartService;
  public userInputs = userInputsFactory() as UserInput;

  constructor(
    public styleParams: IProductPageStyleParams,
    public origPublicData: IProductPageControllerConfig['publicData'],
    private readonly setProps: Function,
    private readonly siteStore: SiteStore,
    private readonly externalId: string,
    private readonly reportError: (e) => any
  ) {
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: StoresWidgetID.PRODUCT_PAGE,
      fedopsAppName: this.isQuickView ? QUICK_VIEW_APP_NAME : PRODUCT_PAGE_APP_NAME,
    });
    if (isWorker()) {
      this.fedopsLogger.appLoadStarted();
      this.isStartReported = true;
    }
    this.productService = new ProductService(siteStore);
    this.navigationContext = this.getNavigationContext();
    this.publicData = _.cloneDeep(this.origPublicData);
    this.currentPath = this.siteStore.location.path;
    this.cartApi = new CartApi(this.siteStore.httpClient);
    if (this.siteStore.experiments.enabled(SPECS.URL_CUSTOMIZE)) {
      this.customUrlApi = new CustomUrlApi(this.siteStore.location.buildUrl);
    }
    this.addToCartService = new AddToCartService(siteStore, this.publicData);

    //eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.siteStore.location.onChange((data) => {
      if (data.path[0] === this.currentPath[0]) {
        return this.setInitialState().catch(this.reportError);
      }
    });
  }

  public async setInitialState(): Promise<void> {
    this.sectionUrl = (await this.siteStore.getSectionUrl(PageMap.PRODUCT)).url;

    this.fedopsLogger.appLoadingPhaseStart('startFetching');
    const isMembersInstalledPromise = this.siteStore.siteApis.isAppSectionInstalled({
      appDefinitionId: APP_DEFINITION_ID,
      sectionId: PageMap.ORDER_HISTORY,
    });

    const [translations, {product, appSettings, countryCodes}, isMembersInstalled] = await all(
      this.getProductPageTranslations(),
      this.getInitialData(),
      isMembersInstalledPromise
    ).catch(this.reportError);

    this.countryCodes = countryCodes;
    this.fedopsLogger.appLoadingPhaseStart('processData');

    this.product = product;
    this.mergedPublicData = this.isQuickView
      ? {...this.publicData.APP, ...this.publicData.COMPONENT}
      : this.publicData.APP || {};
    this.translations = translations;
    this.multilingualService = new MultilingualService(
      this.mergedPublicData,
      appSettings.widgetSettings,
      this.siteStore.getMultiLangFields(),
      this.siteStore.locale
    );
    this.pagePath = await this.getPagePath();

    if (!this.product) {
      return this.handleEmptyState();
    }

    this.productAddedToWishlist = false;
    this.isMembersInstalled = isMembersInstalled;
    this.media = product.media;
    this.productService.updateOptions(this.product);
    this.setInitialUserInputs();
    if (!this.isQuickView) {
      this.siteStore.pubSub.publish(PubSubEvents.RELATED_PRODUCTS, [this.product.id], true);
    }
    this.fedopsLogger.appLoadingPhaseStart('setProps');

    this.subscriptionService = new SubscriptionService(this.product.subscriptionPlans, {
      label: this.translations.PRODUCT_PAGE_ONE_TIME_PURCHASE_LABEL,
      place: this.styleParams.numbers.productPage_subscriptionPlansOneTimePurchase,
      price: this.product.formattedComparePrice || this.product.formattedPrice,
    });

    if (this.siteStore.experiments.enabled(SPECS.CASHIER_EXPRESS_IN_PRODUCT_PAGE)) {
      this.cashierExpressCheckoutWidgetProps = {
        requestShipping: this.product.productType !== ProductType.DIGITAL,
        buttonStyle: {
          shape:
            this.styleParams.numbers.productPage_dynamicPaymentMethodsButtonShape === DynamicPaymentMethodsShape.pill
              ? 'pill'
              : 'rect',
          height: 42,
        },
        domain: this.siteStore.location.baseUrl,
        meta: {
          appDefId: APP_DEFINITION_ID,
          appInstanceId: this.siteStore.storeId,
          siteId: this.siteStore.msid,
          visitorId: this.siteStore.uuid as string,
        },
        currency: this.siteStore.getCurrentCurrency(),
        locale: this.siteStore.locale,
      };
    }

    this.setProps(this.initialProps);
    this.trackViewContent();
    this.setPageMetaData();
    if (this.siteStore.isSSR()) {
      this.fedopsLogger.appLoaded();
    }
  }

  private reportBIOnAppLoaded() {
    const type = this.getImageResizeValue();
    const eventData: exposureEventForTestsParams = {
      isMobileFriendly: this.siteStore.isMobileFriendly,
      testName: this.isQuickView ? BiExposureTestName.QUICK_VIEW : BiExposureTestName.PRODUCT_PAGE,
      is_eligible: true,
      type,
    };

    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.siteStore.biLogger.exposureEventForTests(eventData);
  }

  private reportToWiXCounters() {
    return updateWixCounters(this.siteStore, this.product.id, this.siteStore.uuid);
  }

  public onAppLoaded = () => {
    /* istanbul ignore else: todo: test */
    if (!isWorker() || (this.siteStore.isInteractive() && this.isStartReported)) {
      if (this.siteStore.isSiteMode()) {
        this.reportBIOnAppLoaded();
        this.product && this.reportToWiXCounters();
      }
      this.fedopsLogger.appLoaded(this.getFedopsCustomParams() as any);
      this.isStartReported = false;
    }
  };

  private getFedopsCustomParams() {
    if (!this.product) {
      return {customParams: {product_guid: ''}};
    }

    const customParams: IFedopsCustomParamsFull = {
      product_page_layout: LayoutNames[this.layoutId] as Layout,
      product_guid: this.product.id,
      product_type: this.product.productType as ProductType,
      product_page_images: this.styleParams.fonts.productPage_galleryNavigationType.value,
      is_stretch_to_full: this.styleParams.booleans.full_width,
      image_resize: this.getImageResizeValue(),
      add_to_cart_action:
        //eslint-disable-next-line no-nested-ternary
        this.getAddToCartAction() === AddToCartActionOption.MINI_CART
          ? 'mini-cart'
          : this.getAddToCartAction() === AddToCartActionOption.CART
          ? 'cart'
          : 'none',
      has_wishlist: this.styleParams.booleans.productPage_wishlistEnabled,
      has_buy_now_button: this.styleParams.booleans.productPage_buyNowButtonEnabled,
      has_add_to_cart_button: this.styleParams.booleans.productPage_productAction,
      social_media_bar: this.styleParams.booleans.productPage_socialNetworks,
      store_id: this.siteStore.storeId,
      has_subscribe_now_button: this.subscriptionService.shouldShowSubscriptionPlans,
    };

    if (this.siteStore.experiments.enabled(SPECS.SELLING_IN_UNITS_SF)) {
      customParams.show_unit_price = !!this.product.pricePerUnitData;
    }

    return {customParams: JSON.stringify(customParams)};
  }

  private getImageResizeValue() {
    return this.styleParams.numbers.productPage_galleryImageMode === ImageModeValues.CROP
      ? ImageModeType.CROP
      : ImageModeType.FIT;
  }

  private updatePublicData(newPublicData: IProductPageControllerConfig['publicData']) {
    Object.keys(newPublicData.APP || {}).forEach((key) => {
      this.mergedPublicData[key] = newPublicData.APP[key];
    });
  }

  public updateState(
    newStyleParams: IProductPageStyleParams,
    newPublicData: IProductPageControllerConfig['publicData'] & {appSettings?: any}
  ): void {
    this.updatePublicData(newPublicData);
    this.styleParams = newStyleParams;
    this.multilingualService.setWidgetSettings(newPublicData.appSettings);
    this.setProps({
      ...this.getChangeableSettingsProps(),
    });
  }

  private getTexts() {
    const componentAddToCartTextKey = Object.keys(this.multilingualService.getAll() || {}).find((key) =>
      COMPONENTS_ADD_TO_CART_TEXT_KEYS.includes(key)
    );

    if (componentAddToCartTextKey) {
      MULTILINGUAL_TO_TRANSLATIONS_MAP.ADD_TO_CART_BUTTON = componentAddToCartTextKey;
    }

    const componentOutOfStockTextKey = Object.keys(this.multilingualService.getAll() || {}).find((key) =>
      COMPONENTS_OUT_OF_STOCK_TEXT_KEYS.includes(key)
    );

    if (componentOutOfStockTextKey) {
      MULTILINGUAL_TO_TRANSLATIONS_MAP.PRODUCT_OUT_OF_STOCK_BUTTON = componentOutOfStockTextKey;
    }

    return Object.keys(MULTILINGUAL_TO_TRANSLATIONS_MAP).reduce(
      (acc, translationKey) => {
        const multiligualKey = MULTILINGUAL_TO_TRANSLATIONS_MAP[translationKey];
        const override = this.multilingualService.get(multiligualKey);
        if (override) {
          acc[translationKey] = override;
        }
        return acc;
      },
      {...this.translations}
    );
  }

  private getChangeableSettingsProps(): Partial<IPropsInjectedByViewerScript> {
    this.subscriptionService.setOneTimePurchasePlace(
      this.styleParams.numbers.productPage_subscriptionPlansOneTimePurchase
    );

    return {
      layoutId: this.layoutId,
      shouldShowAddToCartButton: this.styleParams.booleans.productPage_productAction,
      shouldShowBuyNowButton: this.styleParams.booleans.productPage_buyNowButtonEnabled,
      shouldShowWishlistButton: this.styleParams.booleans.productPage_wishlistEnabled && this.isMembersInstalled,
      dynamicPaymentMethodsTheme: this.getDynamicPaymentMethodsTheme(),
      subscriptionPlans: this.subscriptionService.getSubscriptionPlans(),
      texts: this.getTexts(),
      withModalGallery: this.withModalGallery,
    };
  }

  private getDynamicPaymentMethodsTheme(): Theme | undefined {
    if (this.styleParams.numbers.productPage_dynamicPaymentMethodsButtonTheme === DynamicPaymentMethodsTheme.light) {
      return 'light';
    } else if (
      this.styleParams.numbers.productPage_dynamicPaymentMethodsButtonTheme === DynamicPaymentMethodsTheme.dark
    ) {
      return 'dark';
    }
  }

  private get initialProps(): Partial<IPropsInjectedByViewerScript> {
    return {
      ...this.defaultProps,
      ...this.getChangeableSettingsProps(),
      addedToCartStatus: ActionStatus.IDLE,
      addToCartState: this.addToCartState,
      biLogger: this.biLogger.bind(this),
      cashierExpressCheckoutWidgetProps: this.cashierExpressCheckoutWidgetProps,
      closeWixModal: this.closeWixModal.bind(this),
      currentPrice: this.currentPrice,
      errorPlacement:
        this.siteStore.isMobile() || this.isQuickView ? ErrorTooltipPlacement.Bottom : ErrorTooltipPlacement.Left,
      errors: {},
      fetchPaymentBreakdownForCashierAddress: this.fetchPaymentBreakdownForCashierAddress,
      handleAddToCart: this.handleAddToCart,
      handleBuyNow: this.handleBuyNow,
      handleCashierOnClick: this.handleCashierOnClick,
      handleCashierOnClickResult: this.handleCashierOnClickResult,
      handleCashierPaymentSubmit: this.handleCashierPaymentSubmit,
      handleCashierPaymentSubmitResult: this.handleCashierPaymentSubmitResult,
      handleSubscribe: this.handleSubscribe,
      handleUserInput: this.handleUserInput,
      handleWishlistButtonClick: this.handleWishlistButtonClick,
      hasMultipleMedia: this.product.media.length > 1,
      hideNavigationUrls: !this.siteStore.isSiteMode(),
      infoSection: this.infoSection,
      isDesktop: this.siteStore.isDesktop(),
      isEditorMode: this.siteStore.isEditorMode(),
      isEditorX: this.isEditorX,
      isMobile: this.siteStore.isMobile(),
      isProductSubmitted: false,
      isQuickView: this.isQuickView,
      isRTL: this.siteStore.isRTL(),
      isResponsive: this.layoutId === LayoutId.Responsive,
      isSEO: this.siteStore.seo.isInSEO(),
      isSSR: this.siteStore.isSSR(),
      modalState: ModalState.CLOSE,
      navigate: this.navigate.bind(this),
      navigateToLink: /* istanbul ignore next */ (link) => this.siteStore.location.navigateTo(link.sdkLink),
      notifyProduct: this.notifyProductLoaded,
      pagination: this.getPrevNextProducts(),
      product: this.product,
      productUrl: this.productUrl,
      productWasAddedToWishlist: this.productAddedToWishlist,
      quantityRange: this.quantityRange,
      ravenUserContextOverrides: {id: this.siteStore.storeId, uuid: this.siteStore.uuid},
      resetAddedToCartStatus: this.resetAddedToCartStatus,
      resetWishlistStatus: this.resetWishlistStatus,
      selectedVariant: this.selectedVariant,
      shouldFocusAddToCartButton: false,
      shouldShowAddToCartSuccessAnimation: this.getAddToCartAction() === AddToCartActionOption.NONE,
      shouldShowSubscribeButton: this.subscriptionService.shouldShowSubscribeButton(this.userInputs),
      shouldShowSubscriptionPlans: this.subscriptionService.shouldShowSubscriptionPlans,
      siteUrl: this.siteStore.location.baseUrl,
      socialSharing: this.socialSharing,
      subscriptionPlans: this.subscriptionService.getSubscriptionPlans(),
      userInputErrors: userInputsFactory() as UserInputErrors,
      userInputs: this.userInputs,
      validate: this.validate,
      wishlistActionStatus: ActionStatus.IDLE,
    };
  }

  private get defaultProps() {
    return {
      onAppLoaded: this.onAppLoaded,
      experiments: {
        jpgExperiment: this.siteStore.experiments.enabled('specs.stores.SSRJpgImageForPng'),
        isSellingInUnitsEnabled: this.siteStore.experiments.enabled(SPECS.SELLING_IN_UNITS_SF),
        cashierExpressWidget: this.siteStore.experiments.enabled(SPECS.CASHIER_EXPRESS_IN_PRODUCT_PAGE),
      },
      isInteractive: this.siteStore.isInteractive(),
      cssBaseUrl: this.siteStore.baseUrls.productPageBaseUrl,
      pagePath: this.pagePath,
      texts: this.getTexts(),
    } as Partial<IPropsInjectedByViewerScript>;
  }

  private get isEditorX(): boolean {
    return this.styleParams.booleans.responsive === true;
  }

  private get withModalGallery() {
    const hasMedia = this.product.media.length > 0;
    const isViewer = !this.siteStore.isPreviewMode() && !this.siteStore.isEditorMode();
    const applicable = this.styleParams.booleans.productPage_galleryZoom === true || this.siteStore.isMobile();
    const allowedInLayout = [LayoutId.Responsive, LayoutId.Classic].includes(this.layoutId);
    return applicable && hasMedia && isViewer && allowedInLayout;
  }

  private setInitialUserInputs() {
    const textFieldsLength = this.product.customTextFields ? this.product.customTextFields.length : 0;
    const withProductOptions = this.siteStore.experiments.enabled(SPECS.PRODUCT_OPTIONS_IN_GALLERY_COMP);

    const quantity = withProductOptions && this.quickViewParams.quantity ? this.quickViewParams.quantity : 1;
    const selection = this.product.options.reduce((acc, option, i) => {
      if (withProductOptions && this.quickViewParams.selectionIds?.length) {
        acc[i] = this.quickViewParams.selectionIds.find(({id: selectionId}) => {
          return option.selections.find((item) => item.id === selectionId);
        });
      }

      if (!acc[i]) {
        const shouldPreselect = option.selections.length > 1;
        acc[i] = shouldPreselect ? null : option.selections[0];
      }

      return acc;
    }, []);

    this.userInputs = {
      selection,
      quantity: [quantity],
      text: Array(textFieldsLength).fill(null),
      subscriptionPlan: [],
    };

    this.updateSelections();
  }

  private readonly socialSharing = {
    onClick: (data: SocialSharingEvent) => {
      const socialSharingEventWithProduct: socialButtonsParams = {...data, productId: this.product.id};
      return this.siteStore.biLogger.socialButtons(socialSharingEventWithProduct);
    },
  };

  private readonly infoSection = {
    onActive: (BIEvent: InfoSectionEvent) => {
      const infoSectionEventWithProduct: clickOnProductDetailsSfParams = {...BIEvent, productId: this.product.id};
      return this.siteStore.biLogger.clickOnProductDetailsSf(infoSectionEventWithProduct);
    },
  };

  private getProductPageTranslations(): Promise<TranslationDictionary> {
    //eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (this.translationsPromise) {
      return this.translationsPromise;
    }
    this.translationsPromise = getTranslations(
      translationPath(this.siteStore.baseUrls.productPageBaseUrl, this.siteStore.locale)
    );
    return this.translationsPromise;
  }

  private readonly navigate = (productUrlPart: string, closeWixModal: boolean = false): void => {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.siteStore.navigate(
      {
        sectionId: PageMap.PRODUCT,
        queryParams: undefined,
        state: productUrlPart,
      },
      this.siteStore.experiments.enabled(SPECS.URL_CUSTOMIZE)
    );

    if (closeWixModal) {
      //eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.siteStore.biLogger.clickOnProductBoxSf({
        productId: this.product.id,
        hasRibbon: !!this.product.ribbon,
        hasOptions: !!this.product.options.length,
        index: 0,
        productType: this.product.productType,
        origin: 'quick-view',
      });
      this.closeWixModal();
    }
  };

  private closeWixModal() {
    this.siteStore.windowApis.closeWindow();
  }

  private readonly handleUserInput = (inputType: UserInputType, data: UserInputData = null, index: number) => {
    this.userInputs[inputType][index] = data;
    this.updateSelections();
    this.nextProps();
  };

  private readonly updateSelections = () => {
    this.productService.options.updateSelections(this.product, this.userInputs.selection);
    this.filterMedia();
  };

  private readonly nextProps = (additionalProps = {} as Partial<IPropsInjectedByViewerScript>) => {
    const nextProps: Partial<IPropsInjectedByViewerScript> = {
      addToCartState: this.addToCartState,
      currentPrice: this.currentPrice,
      product: this.product,
      quantityRange: this.quantityRange,
      selectedVariant: this.selectedVariant,
      shouldShowSubscribeButton: this.subscriptionService.shouldShowSubscribeButton(this.userInputs),
      subscriptionPlans: this.subscriptionService.getSubscriptionPlans(),
      userInputs: this.userInputs,
      ...additionalProps,
    };

    this.setProps(nextProps);
  };

  public readonly validate = (): void => {
    this.nextProps({
      userInputErrors: this.productService.validate(this.userInputs),
    });
  };

  private readonly createDirectPurchaseService = () => {
    this.directPurchaseService = new DirectPurchaseService(
      this.siteStore,
      this.reportError,
      //eslint-disable-next-line @typescript-eslint/no-misused-promises
      (buttonType: BiButtonActionType) => this.reportButtonAction(buttonType),
      this.nextProps,
      this.fedopsLogger,
      () => this.trackEventForBuyNowAndSubscribe(),
      () => this.handleQuickViewClose(),
      this.isEditorX
    );
  };

  private readonly validateProductSubmission = (): boolean => {
    const validationObject = this.isInvalid(this.userInputs);

    if (validationObject.isInvalid) {
      this.nextProps({
        isProductSubmitted: true,
        userInputErrors: validationObject.validations,
      });
      return false;
    } else {
      return true;
    }
  };

  private readonly handleQuickViewClose = () => {
    if (this.isQuickView) {
      this.siteStore.windowApis.closeWindow();
    }
  };

  private readonly handleCashierOnClick = async () => {
    if (!this.directPurchaseService) {
      this.createDirectPurchaseService();
    }

    //todo(eran): this await is an hack until I fix this: https://wix.slack.com/archives/C68TQQSNM/p1595256383021500
    //eslint-disable-next-line @typescript-eslint/await-thenable
    const shouldProceed = await this.validateProductSubmission();
    if (!shouldProceed) {
      this.nextProps({handleCashierOnClickResult: withChangeListener(false)});
      return;
    }

    const canCheckout = await this.directPurchaseService.handleCashierOnClick(this.product);
    this.nextProps({handleCashierOnClickResult: withChangeListener(canCheckout)});

    this.volatileCartServiceForCashierExpress = new VolatileCartService(this.siteStore.httpClient, this.siteStore);
    await this.volatileCartServiceForCashierExpress.getStandaloneCartId(
      this.product.id,
      this.userInputs.selection.map((selected) => selected.id),
      this.userInputs.quantity[0],
      formatCustomTextFields(this.product, this.userInputs).map((customTextField) => {
        return {title: customTextField.customText.title, value: customTextField.answer};
      })
    );
  };

  private readonly handleCashierPaymentSubmit = async (
    paymentInfo: PaymentAuthorizedArgs,
    accessibilityEnabled: boolean
  ) => {
    const shouldRequestShipping = this.product.productType !== 'digital';

    if (shouldRequestShipping) {
      await this.cartApi.setCartAddresses(
        this.volatileCartServiceForCashierExpress.cartId,
        cashierExpressAddressToEcomAddress(paymentInfo.shippingContact, paymentInfo.billingContact, this.countryCodes)
      );
    } else {
      await this.cartApi.setCartBillingAddresses(
        this.volatileCartServiceForCashierExpress.cartId,
        cashierExpressAddressToEcomAddress({}, paymentInfo.billingContact, this.countryCodes)
      );
    }
    await this.directPurchaseService.handleCheckoutStageWithExistingCart(
      accessibilityEnabled,
      this.product,
      this.volatileCartServiceForCashierExpress.cartId,
      undefined,
      paymentInfo.detailsId
    );
    this.nextProps({handleCashierPaymentSubmitResult: withChangeListener({result: 'success'})});
  };

  private readonly fetchPaymentBreakdownForCashierAddress = async (
    shippingAddress: ShippingContactRestricted
  ): Promise<void> => {
    const {country, subdivision, zipCode} = cashierExpressAddressToEcomAddress(shippingAddress, {}, this.countryCodes);

    await this.cartApi.setShippingAddressesForFastFlow(this.volatileCartServiceForCashierExpress.cartId, {
      country,
      subdivision,
      zipCode,
    });

    const cart = await this.volatileCartServiceForCashierExpress.getCart();
    const notEnoughInfoAboutSubdivision = cart.cartService.cart.destinationCompleteness.includes('SUBDIVISION');

    const totals = cart.cartService.cart.convertedTotals;

    //todo(eran): add shipping and tax only when needed
    const initialPaymentBreakdown: PaymentBreakdown = {
      shipping: totals.shipping.toString(),
      tax: totals.tax.toString(),
      discount: totals.discount.toString(),
      itemsTotal: totals.itemsTotal.toString(),
    };

    if (cart.cartService.cart.shippingRuleInfo.canShipToDestination || notEnoughInfoAboutSubdivision) {
      this.nextProps({
        fetchPaymentBreakdownForCashierAddressResult: withChangeListener({
          paymentAmount: cart.cartService.cart.convertedTotals.total.toString(),
          paymentBreakdown: initialPaymentBreakdown,
        }),
      });
    } else {
      this.nextProps({
        fetchPaymentBreakdownForCashierAddressResult: withChangeListener({
          error: ShippingError.SHIPPING_ADDRESS_UNSERVICEABLE,
        }),
      });
    }
  };

  private readonly handleBuyNow = async (accessibilityEnabled: boolean): Promise<void> => {
    const shouldProceed = this.validateProductSubmission();
    if (!shouldProceed) {
      return;
    }

    this.fedopsLogger.interactionStarted(productPageFedopsEvent.BuyNow);

    /* istanbul ignore else: todo(ariel): test else */
    if (!this.directPurchaseService) {
      this.createDirectPurchaseService();
    }

    return this.directPurchaseService.handleBuyNow(accessibilityEnabled, this.product, this.userInputs);
  };

  private readonly handleSubscribe = async (accessibilityEnabled: boolean): Promise<void> => {
    const shouldProceed = this.validateProductSubmission();
    if (!shouldProceed) {
      return;
    }

    this.fedopsLogger.interactionStarted(productPageFedopsEvent.Subscribe);

    /* istanbul ignore else: todo(ariel): test else */
    if (!this.directPurchaseService) {
      this.createDirectPurchaseService();
    }
    return this.directPurchaseService.handleSubscribe(accessibilityEnabled, this.product, this.userInputs);
  };

  private readonly handleAddToCart = async (): Promise<any> => {
    const validationObject = this.isInvalid(this.userInputs);

    if (validationObject.isInvalid) {
      this.nextProps({
        isProductSubmitted: true,
        userInputErrors: validationObject.validations,
      });
      return;
    }

    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.reportButtonAction(BiButtonActionType.AddToCart);
    this.fedopsLogger.interactionStarted(productPageFedopsEvent.AddToCart);
    this.trackAddToCart();

    const eventId = this.siteStore.pubSubManager.subscribe(
      'Minicart.DidClose',
      () => {
        this.setProps({
          //random so it will never be the same value
          shouldFocusAddToCartButton: Math.random(),
        });

        this.siteStore.pubSubManager.unsubscribe('Minicart.DidClose', eventId);
      },
      true
    );
    this.setProps({addedToCartStatus: ActionStatus.SUCCESSFUL});
    return this.productService.addToCart(
      this.product,
      this.userInputs,
      this.getAddToCartAction(),
      this.biOrigin,
      this.onAddToCartSuccess
    );
  };

  private readonly onAddToCartSuccess = () => {
    this.fedopsLogger.interactionEnded(productPageFedopsEvent.AddToCart);
    this.fedopsLogger.flush();
    this.handleQuickViewClose();
  };

  private readonly resetAddedToCartStatus = () => {
    this.setProps({addedToCartStatus: ActionStatus.IDLE});
  };

  private getHeaders() {
    return {
      Authorization: (this.siteStore.httpClient.getBaseHeaders() as any).Authorization,
    };
  }

  private readonly handleWishlistButtonClick = async () => {
    if (!this.siteStore.usersApi.currentUser.loggedIn) {
      await this.siteStore.usersApi.promptLogin({});
    }

    const wishlistActionPromise = this.productAddedToWishlist
      ? new WishlistActions(this.getHeaders()).removeProducts([this.product.id])
      : new WishlistActions(this.getHeaders()).addProducts([this.product.id]);
    this.toggleWishlistState();
    this.reportWishlistFedops(true);
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.reportWishlistBI();
    await wishlistActionPromise
      .then(() => {
        this.reportWishlistFedops(false);
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.reportWishlistSuccessfulOperationBI();
      })
      .catch(() => {
        this.toggleWishlistState();
      });
  };

  private toggleWishlistState() {
    this.productAddedToWishlist = !this.productAddedToWishlist;
    this.setProps({
      productWasAddedToWishlist: this.productAddedToWishlist,
      wishlistActionStatus: ActionStatus.SUCCESSFUL,
    });
  }

  private readonly resetWishlistStatus = () => {
    this.setProps({
      wishlistActionStatus: ActionStatus.IDLE,
    });
  };

  private getAddToCartAction() {
    let addToCartAction = AddToCartActionOption.MINI_CART;
    if (this.styleParams.numbers.productPage_addToCartAction) {
      addToCartAction = this.styleParams.numbers.productPage_addToCartAction;
    } else if (!this.styleParams.booleans.productPage_openMinicart) {
      addToCartAction = AddToCartActionOption.NONE;
    }
    return addToCartAction;
  }

  private readonly notifyProductLoaded = () => {
    return this.siteStore.windowApis.trackEvent(
      'productPageLoaded' as any,
      {
        productId: this.product.id,
        name: this.product.name,
        currency: this.siteStore.currency,
        price: this.product.price,
        sku: this.product.sku,
      } as any
    );
  };

  private getAddToCartActionName(): string {
    //eslint-disable-next-line no-nested-ternary
    return this.getAddToCartAction() === AddToCartActionOption.MINI_CART && !this.productService.shouldNavigateToCart()
      ? 'mini-cart'
      : this.getAddToCartAction() === AddToCartActionOption.CART ||
        (this.productService.shouldNavigateToCart() && this.getAddToCartAction() !== AddToCartActionOption.NONE)
      ? 'cart'
      : 'none';
  }

  private reportButtonAction(buttonType: BiButtonActionType) {
    const eventData = {
      buttonType,
      appName: 'productPageApp',
      hasOptions: this.userInputs.selection.length > 0,
      productId: this.product.id,
      productType: this.product.productType as ProductType,
      origin: this.biOrigin,
      isNavigateCart: !this.styleParams.booleans.productPage_openMinicart || this.productService.shouldNavigateToCart(),
      navigationClick:
        buttonType === BiButtonActionType.BuyNow || buttonType === BiButtonActionType.Subscribe
          ? 'checkout'
          : this.getAddToCartActionName(),
      quantity: Math.round(this.selectedQuantity),
    };

    return this.siteStore.biLogger.clickOnAddToCartSf(eventData);
  }

  private reportWishlistBI() {
    const eventData = this.createWishlistBiEventData();

    return this.productAddedToWishlist
      ? this.siteStore.biLogger.clickAddToWishlistSf(eventData)
      : this.siteStore.biLogger.clickRemoveFromWishlistSf(eventData);
  }

  private reportWishlistSuccessfulOperationBI() {
    const eventData = this.createWishlistBiEventData();

    return this.productAddedToWishlist
      ? this.siteStore.biLogger.productAddedToWishlistSf(eventData)
      : this.siteStore.biLogger.productRemovedFromWishlistSf(eventData);
  }

  private createWishlistBiEventData() {
    return {
      appName: 'productPageApp',
      hasOptions: this.product.options.length > 0,
      productId: this.product.id,
      productType: this.product.productType as ProductType,
      origin: this.biOrigin,
    };
  }

  private reportWishlistFedops(isInteractionStarted: boolean) {
    const interactionName = this.productAddedToWishlist
      ? productPageFedopsEvent.AddToWishlist
      : productPageFedopsEvent.RemoveFromWishlist;
    return isInteractionStarted
      ? this.fedopsLogger.interactionStarted(interactionName)
      : this.fedopsLogger.interactionEnded(interactionName);
  }

  private trackInitiateCheckout() {
    const variant = this.selectedVariant || this.product;
    const params = {
      ...trackEventMetaData,
      id: this.product.id,
      name: this.product.name,
      price: variant.comparePrice || /* istanbul ignore next */ variant.price,
      currency: this.siteStore.currency,
      quantity: this.selectedQuantity,
    };

    return this.siteStore.windowApis.trackEvent(TrackEventName.INITIATE_CHECKOUT, params);
  }

  private trackAddToCart() {
    const variant = this.selectedVariant || this.product;
    const params: ITrackEventParams = {
      ...trackEventMetaData,
      id: this.product.id,
      name: this.product.name,
      price: variant.comparePrice || /* istanbul ignore next */ variant.price,
      currency: this.siteStore.currency,
      quantity: this.selectedQuantity,
      sku: this.product.sku,
      type: this.product.productType,
    };

    return this.siteStore.windowApis.trackEvent(TrackEventName.ADD_TO_CART, params);
  }

  private trackEventForBuyNowAndSubscribe() {
    this.trackAddToCart();
    this.trackInitiateCheckout();
  }

  private trackViewContent() {
    const params: ITrackEventParams = {
      ...trackEventMetaData,
      id: this.product.id,
      name: this.product.name,
      price: this.product.comparePrice || this.product.price,
      currency: this.siteStore.currency,
      type: this.product.productType,
      sku: this.product.sku,
      dimension3: this.product.isInStock ? 'in stock' : 'out of stock',
    };

    return this.siteStore.windowApis.trackEvent(TrackEventName.VIEW_CONTENT, params);
  }

  private readonly isInvalid = (userInputs: UserInput): {isInvalid: boolean; validations: UserInputErrors} => {
    const validations = this.productService.validate(userInputs);

    const isInvalid = Object.keys(validations).reduce((accValidation: boolean, currentKey: string) => {
      const flag = validations[currentKey].some((value) => value === true);
      return accValidation || flag;
    }, false);

    return {isInvalid, validations};
  };

  private filterMedia(): void {
    const filteredMedia = _.flatMap(this.userInputs.selection, (item) => item?.linkedMediaItems || []);
    this.product.media = filteredMedia.length ? (filteredMedia as any) : this.media;
  }

  private getUrlWithoutParams(url: string): string {
    const parsedUrl = parseUrl(url);
    return `${parsedUrl.protocol}://${parsedUrl.host}${parsedUrl.path}`;
  }

  private get getUrlSegments(): {[key: string]: string} {
    const currentUrl = this.getUrlWithoutParams(this.siteStore.location.url);

    return this.siteStore.siteApis.getUrlSegments(currentUrl);
  }

  private get shouldGetBySlug(): boolean {
    const currentUrl = this.getUrlWithoutParams(this.siteStore.location.url);

    return (
      ((this.siteStore.isEditorMode() || this.siteStore.isPreviewMode()) && this.currentPath.length > 1) ||
      (this.siteStore.isSiteMode() &&
        (this.siteStore.experiments.enabled(SPECS.URL_CUSTOMIZE)
          ? !_.isEmpty(this.getUrlSegments)
          : currentUrl !== this.sectionUrl))
    );
  }

  private get isDefaultInSEO(): boolean {
    const currentUrl = this.getUrlWithoutParams(this.siteStore.location.url);

    return (
      this.siteStore.isSiteMode() &&
      this.siteStore.seo.isInSEO() &&
      (this.siteStore.experiments.enabled(SPECS.URL_CUSTOMIZE)
        ? _.isEmpty(this.getUrlSegments)
        : currentUrl === this.sectionUrl)
    );
  }

  private async getInitialData(): Promise<{
    product: IProductDTO;
    appSettings: IAppSettings;
    countryCodes: GetProductBySlugQuery['localeData']['countries'];
  }> {
    const shouldGetBySlug = this.shouldGetBySlug;

    if (this.isDefaultInSEO) {
      return {product: null, appSettings: {}, countryCodes: null};
    }

    const {catalog, appSettings, localeData} = await (shouldGetBySlug
      ? this.productService.getProductBySlug(this.slug, this.externalId)
      : this.productService.getDefaultProduct(this.externalId));

    return {
      appSettings,
      countryCodes: localeData?.countries,
      product: shouldGetBySlug ? (catalog as any).product : (catalog as any).products.list[0],
    };
  }

  private getPrevNextProducts(): ProductPagePagination {
    return this.siteStore.experiments.enabled(SPECS.URL_CUSTOMIZE)
      ? new NavigationService(
          this.siteStore,
          this.sectionUrl,
          this.navigationContext,
          this.product,
          this.customUrlApi
        ).getPrevNextProducts()
      : new NavigationService(
          this.siteStore,
          this.sectionUrl,
          this.navigationContext,
          this.product
        ).getPrevNextProducts();
  }

  private get slug() {
    if (
      this.siteStore.experiments.enabled(SPECS.USE_LIGHTBOXES) &&
      this.siteStore.windowApis.lightbox.getContext()?.productSlug
    ) {
      return this.siteStore.windowApis.lightbox.getContext().productSlug;
    }
    const {path} = this.siteStore.location;
    if (path.length === 1) {
      throw new Error(`invalid slug path: "${path.toString()}"`);
    }
    const dirtySlug = decodeURIComponent(path[path.length - 1]);
    return dirtySlug.split('?')[0];
  }

  private get quantityRange(): ProdudctQunatityRange {
    const qunatities = QuantityCalculator.getQuantitiesRange(
      this.product,
      this.userInputs.selection as IProductOptionSelection[]
    );
    return {max: qunatities[qunatities.length - 1], min: qunatities[0]};
  }

  private get selectedQuantity(): number {
    return this.userInputs.quantity[0];
  }

  private readonly setPageMetaData = (): void => {
    if (!this.siteStore.isSiteMode()) {
      return;
    }

    let seoData;

    try {
      seoData = JSON.parse((this.product as any).seoJson);
    } catch {
      //
    }

    const productWithPageUrl = {...this.product, pageUrl: this.productUrl};

    const itemData: ProductPageItemData = {
      product: new SeoProductBuilder(productWithPageUrl, {
        productPageBaseUrl: this.siteStore.experiments.enabled(SPECS.URL_CUSTOMIZE)
          ? this.customUrlApi.buildProductPageUrl(
              this.siteStore.siteApis.getUrlSegments(this.getUrlWithoutParams(this.siteStore.location.url))
            )
          : this.sectionUrl,
      }).get(),
      legacySeoData: {
        title: this.product.seoTitle,
        description: this.product.seoDescription,
      },
    };

    this.siteStore.seo.renderSEOTags({
      itemType: 'STORES_PRODUCT',
      itemData,
      seoData,
    });
  };

  private toPagePath(page: StructurePage, transformName: boolean): PagePath {
    return {
      name: transformName ? capitalizeFirstLetters(page.name) : page.name,
      url: page.url && `${this.siteStore.location.baseUrl}${page.url}`,
      sdkLink: {pageId: page.id, type: 'PageLink'},
    };
  }

  private readonly getPagePath = async (): Promise<PagePath[]> => {
    const path = [];
    const siteStructure = await this.siteStore.siteApis.getSiteStructure({includePageId: true});

    const homepage = siteStructure.pages.find((p) => p.isHomePage);
    if (homepage) {
      path.push(this.toPagePath(homepage, true));
    }

    if (this.siteStore.isSSR()) {
      return path;
    }

    const referringPageId = this.navigationContext.pageId;
    if (referringPageId) {
      const refferingPage = siteStructure.pages.find((p) => {
        const notHomepage = !p.isHomePage;
        const notSelf = p.id !== this.siteStore.siteApis.currentPage.id;
        const matchRef = p.id === referringPageId;
        return notHomepage && notSelf && matchRef;
      });
      if (refferingPage) {
        path.push(this.toPagePath(refferingPage, true));
      }
    }

    if (path.length === 0) {
      return [];
    }
    if (this.product) {
      path.push(this.toPagePath({name: this.product.name, url: null, isHomePage: false, id: null}, false));
    }
    path[0].name = this.translations.BREADCRUMBS_HOME;
    path[0].url = this.siteStore.location.baseUrl;
    return path;
  };

  private getNavigationContext(): IStoreFrontNavigationContext {
    let context: IStoreFrontNavigationContext;
    try {
      context = JSON.parse(this.siteStore.storage.local.getItem(STORAGE_PAGINATION_KEY));
    } catch {
      //
    }
    return context || {pageId: undefined, paginationMap: []};
  }

  private get productUrl(): string {
    return `${this.sectionUrl}/${this.product.urlPart}`;
  }

  private get isQuickView(): boolean {
    const byLayout = this.styleParams.numbers.productPage_layoutId === LayoutId.QuickView;
    //eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    const byQuery = typeof location !== 'undefined' && !!location.search.match(/layout=quickview/);
    return byLayout || byQuery;
  }

  public getSelectionById(id: number) {
    let selection = null;
    //eslint-disable-next-line
    this.product.options.some((option) => {
      selection = option.selections.find((item) => item.id === id);
      return selection;
    });
    return selection;
  }

  public get quickViewParams() {
    const params: {
      selectionIds?: IProductOptionSelection[];
      quantity?: number;
    } = {};

    if (!this.isQuickView) {
      return params;
    }

    const rawSelectionIds = URLUtils.getParameterByName('selectionIds');
    if (rawSelectionIds) {
      const selectionIds = decodeURIComponent(rawSelectionIds)
        .split(',')
        .map((id) => parseInt(id, 10));
      params.selectionIds = selectionIds.map((id) => this.getSelectionById(id));
    }

    const quantity = URLUtils.getParameterByName('quantity');
    if (quantity) {
      params.quantity = parseInt(quantity, 10);
    }

    return params;
  }

  /* istanbul ignore next */
  public biLogger(event: BILoggerEvents, params: Record<string, any> = {}): void {
    (this.siteStore.biLogger[event] as Function).call(this.siteStore.biLogger, params);
  }

  private get biOrigin(): string {
    return this.isQuickView ? Origin.QUICK_VIEW : Origin.PRODUCT_PAGE;
  }

  private get layoutId(): LayoutId {
    return this.isQuickView ? LayoutId.QuickView : this.styleParams.numbers.productPage_layoutId;
  }

  private get selectedVariant() {
    return this.productService.options.selectedVariant;
  }

  private get addToCartState(): AddToCartState {
    const price = actualPrice(this.product, this.selectedVariant);
    const inStock = productInStock(this.product, this.selectedVariant);
    return this.addToCartService.getButtonState({price, inStock});
  }

  private handleEmptyState() {
    if (this.siteStore.seo.isInSEO()) {
      this.siteStore.seo.setSeoStatusCode(404);
    }
    this.setProps({
      ...this.defaultProps,
      product: null,
      layoutId: LayoutId.EmptyState,
    });
  }

  private get selectedPlan() {
    return this.userInputs.subscriptionPlan[0];
  }

  private get currentPrice(): CurrentPrice {
    return new ProductPriceService({
      product: this.product,
      selectedVariant: this.selectedVariant,
      selectedPlan: this.selectedPlan,
      useDiscountedPrice: this.siteStore.experiments.enabled(ADD_FREE_PRODUCTS_TO_CART_SPEC),
    }).getCurrentPricing();
  }
}
